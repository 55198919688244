<template>
  <div>
    <Row>
      <i-col span="24">
        <Select v-model="chooseCompanyId">
            <Option v-for="item in childCompanyList" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
      </i-col>
    </Row>
    <Row class="p-t-5 p-b-5 text-center">
      <i-col span="24">
        <RadioGroup v-model="chooseFeature" size="small" type="button" button-style="solid">
            <Radio label="1" style="width:80px">商业</Radio>
            <Radio label="2" style="width:80px">小微</Radio>
            <Radio label="3" style="width:80px">公益</Radio>
            <Radio label="4" style="width:85px">广告商城</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row class="p-b-5 p-l-5">
      <i-col span="16" class="title">签约额</i-col>
      <i-col span="8" class="text-right">{{formatMoney(contractAmountBean.value)}}</i-col>
    </Row>
    <Row class="p-b-5 p-l-5" v-if="publisherId!==21">
      <i-col span="16" class="title">折扣率</i-col>
      <i-col span="8" class="text-right">{{contractAmountBean.rate}} %</i-col>
    </Row>
    <Row class="p-b-5 p-l-5">
      <i-col span="16" class="title">合同数</i-col>
      <i-col span="8" class="text-right">{{contractAmountBean.number}}</i-col>
    </Row>
    <Row class="p-b-5 p-l-5">
      <i-col span="16" class="title">带附件合同</i-col>
      <i-col span="8" class="text-right">{{contractFileNumber}}</i-col>
    </Row>

    <template v-if="gbTypeArray.length>0">
      <h2 class="text-center p-t-20">合同行业分布</h2>
      <Row v-if="gbTypeArray.length>1" class="p-t-10">
        <i-col span="24">
          <RadioGroup v-model="chooseGbType" type="button" button-style="solid">
            <Radio v-for="(gbType,index) in gbTypeArray" :key="index" :label="gbType.key">{{gbType.label}}</Radio>
          </RadioGroup>
        </i-col>
      </Row>
      <Table class="m-t-5" size="small" :columns="portraitColumn" :data="portraitData" :row-class-name="rowClassName"
        @on-row-click="handleClickRow"></Table>
    </template>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
// import { getCompany } from '@/api/os/company'
import { listChildrenCompany, countContractAmount, countHaveFileNumber, countContractAmountGb } from '@/api/dw/datav'

export default {
  data () {
    return {
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      industryType: this.$store.getters.token.publisherSetting.industryType,
      contractBoard: this.$store.getters.token.publisherSetting.contractBoard,

      chooseCompanyId: '0',
      chooseFeature: '1', // 合同类型
      chooseGbType: '',
      chooseTableId: null,

      childCompanyList: [],
      contractAmountBean: {},
      contractFileNumber: 0,
      gbTypeArray: [],

      portraitColumn: [
        { title: '名称', key: 'name', tooltip: true },
        {
          title: '签约额(元)',
          align: 'right',
          width: 120,
          render: (h, params) => {
            return h('span', toMoney(params.row.value))
          }
        },
        {
          title: '占比',
          align: 'right',
          width: 65,
          render: (h, params) => {
            return h('span', params.row.rate + '%')
          }
        }
      ],
      portraitData: []
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.gbTypeArray = []

      if (this.industryType === 2) {
        this.gbTypeArray.push({ key: 'inter_industry', label: '按平台行业' })
      } else {
        // 如果被设置了允许显示的值， 那么获取相应显示，否则将全部显示
        if (this.contractBoard.includes(1)) {
          this.gbTypeArray.push({ key: 'industry', label: '按用户行业' })
        }

        if (this.contractBoard.includes(2)) {
          this.gbTypeArray.push({ key: 'inter_industry', label: '按平台行业' })
        }
      }

      this.chooseCompanyId = this.companyId
      this.loadCompanyList()
      this.loadContractAmount()
      // 如果在租户配置中设置有值
      if (this.gbTypeArray.length > 0) {
        this.chooseGbType = this.gbTypeArray[0].key
        this.loadContractList()
      }
    },
    loadCompanyList () {
      // if (this.companyId !== '0') { // 表示代理商
      //   getCompany({ companyId: this.companyId }).then(res => {
      //     if (res && !res.errcode) {
      //       this.childCompanyList = [
      //         { id: this.companyId, name: res.name }
      //       ]
      //     }
      //   })
      // } else {
      const postData1 = {
        endDate: this.searchSchedule.endDate,
        loginCompanyId: this.loginCompanyId,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        type: 2 // 2代理商;3运维公司
      }
      listChildrenCompany(postData1).then(res => {
        this.childCompanyList = res
      })
      // }
    },
    loadContractAmount () {
      const postData1 = {
        contractFeature: this.chooseFeature,
        publisherId: this.publisherId,
        companyId: this.chooseCompanyId,
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate
      }
      countContractAmount(postData1).then(res => {
        this.contractAmountBean = res
      })

      const postData2 = {
        orderType: this.chooseFeature,
        publisherId: this.publisherId,
        companyId: this.chooseCompanyId,
        endDate: this.searchSchedule.endDate,
        startDate: this.searchSchedule.startDate
      }
      countHaveFileNumber(postData2).then(res => {
        if (res.length > 0) {
          this.contractFileNumber = res[0].value
        }
      })
    },
    loadContractList () {
      this.chooseTableId = null

      const postData = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        companyId: this.chooseCompanyId,
        gbType: this.chooseGbType,
        contractFeature: this.chooseFeature
      }
      countContractAmountGb(postData).then(res => {
        this.portraitData = res
      })
    },
    formatMoney (money) {
      return toMoney(money)
    },
    rowClassName (row, index) {
      return row.id === this.chooseTableId ? 'situation-table-choose-row' : 'situation-table-row'
    },
    handleClickRow (params, index) {
      this.chooseTableId = (this.chooseTableId === params.id) ? null : params.id
      this.$store.commit('set_situation_gbId', this.chooseTableId)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadCompanyList()
        this.loadContractAmount()
        this.loadContractList()
      }
    },
    chooseCompanyId (val) {
      this.$store.commit('set_situation_companyId', val)
      this.loadContractAmount()
      this.loadContractList()
    },
    chooseFeature (val) {
      this.loadContractAmount()
      this.loadContractList()
      this.$store.commit('set_situation_orderType', val)
    },
    chooseGbType (val) {
      this.loadContractList()
      this.chooseTableId = null
      this.$store.commit('set_situation_gbType', val)
      this.$store.commit('set_situation_gbId', null)
    }
  }
}
</script>
